import { useReference } from './useReference'
import { useCompany } from './useCompany'

export function useStock(stockData = {}) {
  const stock = {
    uid: stockData.uid,
    company: stockData.company ? useCompany(stockData.company) : {},
    quantity: stockData.quantity,
    batchSize: stockData.batch_size || stockData.batchSize,
    previousQuantity: stockData.previous_quantity || stockData.previousQuantity,
    quantityType: stockData.quantity_type || stockData.quantityType,
    unitPrice: stockData.unit_price || stockData.unitPrice,
    unitPriceWithVAT: stockData.unit_price_with_vat || stockData.unitPriceWithVAT,
    unitPriceWithoutCommission: stockData.unit_price_without_commission || stockData.unitPriceWithoutCommission,
    price: Number(stockData.price),
    priceVAT: Number(stockData.price_vat || stockData.priceVAT),
    fee: Number(stockData.fee),
    totalOrderPrice: Number(stockData.total_price || stockData.totalPrice),
    totalOrderPriceVAT: Number(stockData.total_price_vat || stockData.totalOrderPriceVAT || 0), // TODO remove default,
    totalOrderPriceNoVATNC: Number(stockData.total_price_without_fee || stockData.totalOrderPriceNoVATNC),
    previousTotalOrderPrice: Number(stockData.previous_total_price || stockData.previousTotalPrice),
    previousTotalOrderPriceVAT: Number(stockData.previous_total_price_vat || stockData.previousTotalPriceVat || 0),
    previousTotalOrderPriceNoVATNC: Number(
      stockData.previous_total_price_without_fee || stockData.previousTotalOrderPriceNoVATNC
    ),
    priceWithoutCommission: stockData.price_without_commission || stockData.priceWithoutCommission,
    sellingQuantity: stockData.selling_quantity || stockData.sellingQuantity || 'by_unit',
    selectedQuantity: stockData.selected_quantity || stockData.selectedQuantity,
    reference: stockData.reference,
    storage: stockData.storage,
    cartQuantity: stockData.current_cart_quantity || stockData.cartQuantity,
    location: stockData.location,
    publicAt: typeof stockData.is_public === 'undefined' ? new Date(stockData.publicAt) : new Date(stockData.public_at),
    expiryDate: stockData.expiry_date || stockData.expiryDate,
    expiryDateStatus: stockData.expiry_date_status || stockData.expiryDateStatus,
    hasDelivery: stockData.has_delivery || stockData.hasDelivery,
    hasAlerts: stockData.has_alerts || stockData.hasAlerts,
    alerts: stockData.alerts,
    isPublic: typeof stockData.is_public === 'undefined' ? stockData.isPublic : stockData.is_public,
    isArchived: typeof stockData.is_archived === 'undefined' ? stockData.isPublic : stockData.is_archived,
    user: stockData.user,
    internalName: stockData.internal_name,
    issueDetails: stockData.issue_details,
    issueKind: stockData.issue_kind,
    viprosPoints: stockData.vipros_points || stockData.viprosPoints,
    viprosCashback: stockData.vipros_cashback || stockData.viprosCashback,
    note: stockData.note,
    mkpReservedQty: stockData.mkp_reserved_qty,
    tags: stockData.tags,
    source_id: stockData.source_id,
  }
  stock.distance = stock.storage ? stock.storage?.distance : null

  const buyQuantity = () => {
    if (isBatch() && stock.quantity !== null) {
      // When it's a batch, quantity means the batch quantity not the item available quantity
      return 1
    }

    return stock.quantity
  }

  const distanceKm = () => {
    if (stock.storage !== null && stock.storage !== undefined) {
      if (stock.distance !== null && stock.distance !== undefined) {
        if (stock.distance >= 1000) {
          return `${(stock.distance / 1000)?.toFixed(1) || 0}km`
        }
        return `${stock.distance}m`
      }
    }
    return null
  }

  const formattedDistance = () => {
    if (stock.storage !== null && stock.storage !== undefined) {
      if (distanceKm()) {
        return distanceKm()
      }

      if (stock.storage?.city !== null && stock.storage?.city !== undefined && stock.storage?.city !== '') {
        return `${stock.storage?.zip_code} ${stock.storage?.city}`
      }
    }

    return null
  }

  const unit = () => {
    return stock.quantityType ? stock.quantityType?.toUpperCase() : 'UNIT'
  }

  const isBatch = () => {
    return stock.sellingQuantity === 'by_batch'
  }

  // const stockUnitForQuantity = computed(() => {
  //   return stockUnitWithQuantity(stock.quantity)
  // })

  const packagingLabel = () => {
    if (isBatch()) return 'product.byBatch'
    return 'product.theUnit'
  }

  const o_reference = () => {
    return useReference(stock.reference || {})
  }

  const isBooked = () => {
    return stock.user !== null && stock.user !== undefined && Object.keys(stock.user).length !== 0
  }

  const isEditable = () => {
    return !isBooked() && !stock.isPublic
  }

  const bookedByUser = () => {
    if (isBooked()) {
      return `${stock.user?.first_name} ${stock.user?.last_name}`
    }
    return null
  }

  const mainStorage = () => {
    if (subStorage() !== null) {
      return stock.storage?.parent
    }
    return stock.storage
  }

  const subStorage = () => {
    if (stock.storage?.parent !== null && stock.storage?.parent !== undefined) {
      return stock.storage
    }
    return null
  }

  const alert = () => {
    if (stock.hasAlerts) {
      return stock.alerts[0]
    }

    return null
  }

  const f_alertColor = () => {
    if (alert()?.is_reached) return 'u-c-grapefruit'
    else return 'u-c-primary'
  }

  const storePrice = (vat = true) => {
    return vat ? Number(stock.priceVAT) : Number(stock.price)
  }

  const catalogPriceEquivalent = (vat = true) => {
    const { country } = useCurrentLocale()
    if (stock.reference?.price <= 0) {
      return 0
    }
    let refPrice = Number(stock.reference?.price)
    if (vat)
      refPrice =
        Number(stock.reference?.price_vat || stock.reference?.recommended_price_vat) ||
        Number(refPrice * (country?.VAT || 1))
    return refPrice
  }

  const savings = (vat = true) => {
    const price = storePrice(vat)
    const value = catalogPriceEquivalent(vat) - price
    return Math.max(0, value)
  }

  const percentageOff = () => {
    const value = -(savings(true) / catalogPriceEquivalent(true))
    return Math.min(0, value)
  }

  // const stockUnitWithQuantity = quantity => {
  //   if (quantity > 1) return `${unit()}s`
  //   return unit()
  // }

  return {
    ...stock,
    buyQuantity: buyQuantity(),
    distanceKm: distanceKm(),
    formattedDistance: formattedDistance(),
    unit: unit(),
    isBatch: isBatch(),
    // stockUnitForQuantity: stockUnitForQuantity.value,
    packagingLabel: packagingLabel(),
    o_reference: o_reference(),
    isBooked: isBooked(),
    isEditable: isEditable(),
    bookedByUser: bookedByUser(),
    mainStorage: mainStorage(),
    subStorage: subStorage(),
    alert: alert(),
    f_alertColor: f_alertColor(),
    storePrice: storePrice,
    catalogPriceEquivalent: catalogPriceEquivalent,
    savings: savings,
    percentageOff: percentageOff,
  }
}
